.homepage {
        .sidebar {
                //height: 450px;
                
                @include breakpoint($xl) {
                        height: 650px;
                }
        } 
        
        .header {            
                &__title {
                        margin-bottom: 23px;
                        
                        @include breakpoint($xl) {
                                margin: 0 0 24px;
                        }
                }                
        }
        
        .aside--bootom {
                padding: 0 20px;
                
                @include breakpoint($xl) {
                        display: none;
                }
        }
        
        .navbar {   
                //max-height: 100%;
                //padding: 20px 0;
                &--body {
/*                        display: none;
                        
                        @include breakpoint($xl) {
                                display: block;
                        }*/
                }
                
                &__menu {                        
                        padding: 5px 0;
                        letter-spacing: 1px;
                }   
                
                
                .tickertext {                        
                        color: $c_white;
                        font-size: 18px;
                        
                        @include breakpoint($xl) {
                                position: absolute;
                                top: 30px;
                                left: 0;                            
                                padding: 0 160px 0 32px;
                        }
                        
                        a {
                                color: $c_white;
                                transition: ease-in-out .2s;
                                
                                &:hover,
                                &:focus {
                                        color: $c_blueText;
                                        text-decoration: none;
                                }
                        }
                }
        }
        
        .nav-mobile {
                @include breakpoint($xl) {
                        display: none;
                }
        }
        
        .menu-item--v01 {
                display: none; 
        }
        
        .menu-item--v03 {
                display: block;
                
                @include breakpoint($xl) {
                        padding-right: 40px;                    
                }
        }
        
        .row.padding [class*="span"] {
                padding-top: 0;
                padding-bottom: 0;
        }
        
        &.navigation-open {
                .navbar {
                     
                }
        }
}

.menu-item--v03 {
        display: none;
}

.blog {
    .menu-item--v02 {
            display: none;
    }
    
    .menu-item--v03 {
            display: block;
    }
}

.portfolio {

}