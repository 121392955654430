.navbar {
        margin: 0 -30px;
        padding: 0 30px;
    
        @include breakpoint($xl) {
                margin: 0;
                padding: 0;
        }
        
        max-height: 0;
        transition: max-height .4s ease-out;
        overflow: hidden;
        
        @include breakpoint($xl) {
                max-height: initial;
                overflow: visible;
        }
}

.navigation-open {
        .navbar {
                max-height: 320px;
                transition: max-height .5s ease-in;
        }
}

.nav-toggle {       
    position: relative;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px; 
    padding: 10px;
    float: right;    
    background-color: $c_white;        
    border-radius: 0;
    border: 1px solid $c_theme;
    cursor: pointer;
    
    span, 
    span:after, 
    span:before {
            content: '';
            width: 100%;
            height: 3px;
            border-radius: 3px;
            background-color: $c_theme;        
            backface-visibility: hidden;
            transition: all 0.3s;
    }  
    
    span {
            position: relative;
            display: block;
            
            &:after, 
            &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: -5px;
            }
            
            &:after {
                top: 5px;
            }
            
            &:before {

            }
    }
    
    @media (min-width: $xl) {
            display: none;
            width: 50px;
            height: 50px;
            padding: 14px;

            span {
                &:before {
                        top: -7px;
                }

                &:after {
                        top: 7px;
                }
            }
    }
    
    &[aria-expanded="true"] {
        span {
            background-color: transparent;
            
            &:after {
                transform: rotate(-45deg) translate(4px, -3px);
                background-color: $c_theme;
            }
            
            &:before {
                transform: rotate(45deg) translate(4px, 3px);
                background-color: $c_theme;
            }
            
            @media (min-width: $xl) {
                &:after {
                    transform: rotate(-45deg) translate(5px, -5px);
                }
                
                &:before {
                    transform: rotate(45deg) translate(5px, 5px);
                }
            }
        }
    }
}