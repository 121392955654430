
/*	COLORS
	
	green: 			#9BC42C
	grey:			#464646
	black:			#000
	
*/

/*

	font-family: Tahoma, sans-serif;
	font-family: Arial, sans-serif;

*/


/* Reset */
/* Handled by reset.css from https://github.com/murtaugh/HTML5-Reset */

/* GENERAL */

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

*,html,body {
/*	font-family: Tahoma, sans-serif;*/
/*	font-size:16px;*/
/*	font-weight:200;*/
	margin:0;
	padding:0;
/*	color:#464646;*/
}
html {
	/*overflow-x: hidden;*/
	background-color:#e6e6e6;
}
input {
	font-family: inherit;
}
a img {
	border: 0;
}
img{
	margin:0;
	max-width:100%;
}
img.alignleft{
	float:left;
	margin-right:1em;
}
img.alignright{
	float:right;
	margin-left:1em;
}
img.aligncenter{
	display:block;
	margin:1em auto;
}
mark{
	background:none; /*remove default styling*/
}
ul,ol{
	margin-left:1em;
}
table,thead,tbody,tfoot,tr,td{
	border:1px solid #000;
}
table{
	border-collapse:collapse;
	margin:1em 0;
}
td{
	padding:4px;
}

/* FONTS */

/*h1{
	color:#000;
	font-family:'Tahoma', sans-serif;
	font-size:48px;
	font-weight:400;
	margin:0;
	padding:0;
	text-align:left;
}
h2{
	font-family:'Tahoma', sans-serif;
	font-size:28px;
	margin:0;
	padding:0;
}
h3{
	color:#178ecf;
	font-family:'Tahoma', sans-serif;
	font-size:24px;
	font-weight:700;
    text-align:left;
	float:left;
	margin:0;
}
h4{
	color:fff;
	font-family:'Tahoma', sans-serif;
	font-size:20px;
	font-weight:200;
    text-align:left;
	float:left;
	margin:0;
}
p{
	margin:0 0 1em 0;
	font-size:16px;
	line-height: 22px;
	color: #2B2B2B;
}*/
hr{
	width: 100%;
	border: 0;
}
strong{
	font-size:inherit;
	font-weight:bold;
}
/* LINKS */

a{
	color:#178ecf;
}
a:hover{
	color:#1a9de6;
}


.clear{
	clear:both;
}

/* GRID */

.row:before, .row:after {
    content: "";
    display: table;
    line-height: 0;
}
.row   {
	position: relative;
	width: 100%;
	/*overflow: hidden;*/
}
@include breakpoint($xl) {  
    .row [class*="span"] /* wildcard, used to detect all elements within .row starting with 'span' */ { 
            float: left;
    }
}
.row.no-overflow {
	overflow: visible;
}
.centered.row {
	width: 708px;
	margin: 0 auto;
	clear: both;
}
@include breakpoint($xl) {  
    .span1 {
            width: 8.333333%;
    }
    .span2{
            width: 16.66666%;
    }
    .span3{
            width: 25%;
    }
    .span4{
            width: 33.333333%;
            position: relative;
    }
    .span5{
            width: 41.666666%;
    }
    .span6{
            width: 50%;
    }
    .span7{
            width: 58.33333%;
    }
    .span8{
            width: 66.66666%;
    }
    .span9{
            width: 75%;
    }
    .span10{
            width: 83.33333%;
    }
    .span11{
            width: 91.666666%;
    }
    .span12{
            width: 100%;
    }
}
/* GRID WITH PADDING */

.row.padding [class*="span"] {
        padding: $padding;
        
        @include breakpoint($xl) {
                padding: $padding-xl;            
        }        
}
.row-inner{
        margin-left: -20px;
        margin-right: -20px;
        
        @include breakpoint($xl) {
                margin-left: -$padding-xl;    
                margin-right: -$padding-xl;              
        }
}
.row.no-float [class*="span"] {
	margin: 0 auto !important;
	float: none;
}
.row [class*="span"].text-center {
	text-align: center;
}

/* GRID WITH MARGIN */

.row.margin [class*="span"] {
	margin: 2%;
}
.margin .span1 {
	width: 4.333333%;
}
.margin .span2{
	width: 12.66666%;
}
.margin .span3{
	width: 21%;
}
.margin .span4{
	width: 29.333333%;
}
.margin .span5{
	width: 37.666666%;
}
.margin .span6{
	width: 46%;
}
.margin .span7{
	width: 54.33333%;
}
.margin .span8{
	width: 62.66666%;
}
.margin .span9{
	width: 71%;
}
.margin .span10{
	width: 79.33333%;
}
.margin .span11{
	width: 87.666666%;
}
.margin .span12{
	width: 96%;
}

.no-margin{
	margin:0;
}

div.centered{
	margin:0 auto;
	max-width: 630px;
	/*overflow:hidden;*/
        @include breakpoint($xl) {
            max-width: 980px;
        }
}

/* SITE SPECIFIC */



/* CONTACT FORM 7 */
.contact_form_header{
	margin:0 2%;
	padding:0 10px;
}
.wpcf7-form input,
.wpcf7-form textarea{
	width:100%;
	font-size:18px;
	padding:12px;
	border:1px solid #d5e0ea;
}
.wpcf7-form input[type="submit"]{
	display:block;
	width:auto;
	float:right;
	text-align:center;
	padding:12px 30px;
	background-color:#178ecf;
	font-size:22px;
	color:#fff;
	border:none;
	cursor:pointer;
}
.wpcf7-form input[type="submit"]:hover{
	background:#1a9de6;
}
div.wpcf7-response-output{
	margin:0 3%;
}
div.wpcf7-validation-errors{
	
}


/* FOOTER */



/* RESPONSIVE */

@media all and (max-width: 1200px){

}
@media all and (max-width: 1140px){
	
}
@media all and (max-width:980px){
	
}
@media all and (max-width:900px){
	
}
@media all and (max-width:700px){
	
	
}