$f_theme: 'Quicksand', sans-serif;

$c_white: #fff;
$c_black: #000;
$c_gray: #ccc;
$c_ground: #fff;
$c_text: #000;
$c_hover: #23A3E3;
$c_theme: #37AFDA;
$c_blue: #4ec1eb;
$c_blueText: #04acea;
$c_bar: #4A4A48;
$c_text: #1C1C1C;

$xl : 980px;
$padding: 0 20px;
$padding-xl: 12.5px; 

.visible-sm {
        @include breakpoint($xl) {
                display: none;
        }
}