html {
   height: 100%;
}

body {
   min-height: 100%;
}

body {        
        background-image: url(../assets/bg-gradient.jpg); 
        background-attachment: fixed;
        background-repeat: repeat-x;
        background-position: center bottom;
        background-size: cover;
        color: $c_text;
        font-family: $f_theme;
        font-size: 14px;	
        line-height: 22px;
        letter-spacing: -0.055em;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
}

*,
button {
        outline: none;        
        
        &:hover,
        &:focus {
                outline: none;
        }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
        margin: 0;
        padding: 0;
        cursor: default;
}

p {
        margin: 0 0 20px
}

a {
        color: $c_theme;  
        text-decoration: none;
        
        &:hover,
        &:focus {
                color: $c_theme;
                text-decoration: underline;
        }
}

button {
       cursor: pointer;
}

.content {
        padding: 70px 0 0;
        
        @include breakpoint($xl) {
                padding: 0;
        }
}

.sidebar {               
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1001;
        width: 100%;
        padding: $padding;        
        
        @include breakpoint($xl) {                                
                left: initial;
                width: 300px;
                height: 100%;
                padding: 0;
                background-image: url('../assets/bg-transparent.png');
        }
        
        &--body{
                position: relative;                
                
                @include breakpoint($xl) {
                        height: 100%;
                        padding: 15px 20px 20px 20px;
                }
        }
        
        .navbar {            
                @include breakpoint($xl) {                    
                        position: absolute;
                        right: 0;
                        bottom: 15px;
                        width: 100%;
                }
                
                &--body {
                        position: relative;
                        
                        @include breakpoint($xl) {
                            &:before{
                                    content: '';
                                    position: absolute;
                                    right: 100%;
                                    height: 100%;
                                    width: 9999px;                           
                                    display: block;
                                    background-color: $c_bar;
                            }
                        }
                }
                
                &__title{                        
                        @include breakpoint($xl) {                            
                                position: absolute;
                                bottom: 100%;
                                width: 100%;
                                text-align: center;
                        }
                }
        }
        
        .aside,
        .header__title,
        .navbar__title {
                display: none;
                
                @include breakpoint($xl) {  
                        display: block;
                }
        }
}

.nav-mobile {        
        position: fixed;
        top: 70px;
        left: 0;
        z-index: 0;
        width: 100%;    
        margin: 0!important;
        padding: 0 20px;
        background-color: $c_bar;
}

.navbar--bottom {        
        margin: 0!important;
        padding: 20px!important;
        max-height: 100%!important;
        
        @include breakpoint($xl) {
                margin: 0!important;
                padding: 0!important;
                max-height: 100%!important;
        }
        
        .navbar__menu {
                display: none;
                
                @include breakpoint($xl) {
                        display: block;
                }
        }
}

.header {   
        margin-bottom: -20px;
        padding: 10px 0;
        
        @include breakpoint($xl) {
                margin-bottom: 0;
                padding: 0;
        }
    
        &__logo {   
                display: inline-block;                
                width: 100px;
                height: 55px;
                background-image: url('../assets/logo.png');
                background-position: top left -7px;
                background-repeat: no-repeat;
                background-size: contain;
            
                @include breakpoint($xl) {  
                        display: block;
                        width: 260px;
                        height: 96px;
                        margin: 0 0 24px;
                        text-align: center;                
                        background-image: url('../assets/logo.png');
                        background-position: top left -7px;
                        background-repeat: no-repeat;
                        background-size: initial;
                }
                
                @media all and (-webkit-min-device-pixel-ratio: 1.5) {
                        background-image: url('../assets/logo@2x.png');
                        background-position: top left;
                        background-size: 100px 55px;
                        width: 100px;
                        height: 55px;
                        margin: 0;
                        
                        @media (min-width: $xl) { 
                                background-size: 260px 120px;
                                width: 260px;
                                height: 120px;
                        }
                        
                }
                                
                //@media all and (min-width: $xl) and (-webkit-min-device-pixel-ratio: 1.5) {
                        
                //}
                        
               
                img {
                        max-width: 91px;
                        
                        @include breakpoint($xl) {
                                max-width: inherit;
                        }
                }
        }
        
        &__title {
                padding: 0 0 0 10px;
                color: $c_theme;
                text-transform: uppercase;
                
                @include breakpoint($xl) {  
                            margin: 0 0 33px;
                }
        }              
}

.aside {
        p{                
                line-height: 160%;
        }
}

.navbar {
        background-color: $c_bar;
        
        &--body {
                position: relative;
        }
        
        &__title {
                color: $c_theme;
                font-size: 32px;
                line-height: 34px;
                font-weight: normal;
                letter-spacing: -0.08em;
                text-transform: uppercase;

                @include breakpoint($xl) {
                        font-size: 45px;
                }
        }
        
        &__menu { 
                padding: 4px 0 3px;
                
                .menu-item {
                        @include breakpoint($xl) {
                                &.menu-item--v01 {
                                        padding-right: 70px;
                                }
                                
                                &.menu-item--v02 {
                                        padding-right: 70px;
                                }
                                
                                &.menu-item--v03 {
                                        padding-right: 45px;
                                }
                                
                                &.menu-item--v04 {
                                        padding-right: 30px;
                                }
                                
                                &.menu-item--v05 {
                                        padding-right: 50px;
                                }                        
                        }
                }
        }
}

.main {
        padding: 22px 0;
}

.menu {
        list-style-type: none;
        text-align: right;
        
        li {
                line-height: 26px;
                
                @include breakpoint($xl) {
                        line-height: 16px;
                }
                
                a {
                        color: $c_white; 
                        font-size: 18px;
                        line-height: 18px;
                        text-transform: uppercase;
                        text-decoration: none;
                        transition: ease-in-out .2s;
                        
                        &:hover,
                        &:focus {
                                color: $c_hover; 
                        }    
                }
        }
}

.list-posts {
        max-width: 280px;
        margin: 0 auto;
        
        @include breakpoint(560px) {
                max-width: initial;
                margin: 0 -15px;
        }
        
        .list-item {
                margin: 0 0 21px;
                
                @include breakpoint(560px) {
                        display: inline-block;
                        width: 50%;
                        float: left;
                        margin: 0 0 21px;
                        padding: 0 15px;

                        &:nth-child(2n+3){
                                clear: both;
                        }
                }
                
                &:after {
                        content: '';
                        display: table;
                        clear: both;
                }
        }
}

.list-posts {        
        list-style-type: none;
    
        &:after {
            content: '';
            display: table;
            clear: both;
        }
        
        a{
                color: $c_text;
                text-decoration: none;
                
                .list-posts__teaser {
                        cursor: pointer;
                }
        }
        
        .list-item {                                         
                &__body {
                        display: block;
                }
        }
        
        &__info,
        &__title {
                display: none;
        }
        
        &__title {
                margin: 0 0 18px;
                color: $c_theme;
                
                a {
                        color: $c_theme;
                }
        }
        
        &__title--sub {
                margin: 0;
                text-decoration: underline;
                text-transform: uppercase;
        }
        
        &__teaser {
                cursor: default;
                
                p {
                    margin: 0;
                }
        }
        
        &__more {
                margin: 20px 0 0;
                float: right;
        }
}

.list-menu {
        list-style: none;
        
        .cat-item {
                a {            
                        position: relative;
                        padding-left: 11px;
                        color: $c_text;
                        font-size: 16px;
                        line-height: 18px;
                        transition: ease-in-out .2s;
                        
                        &:hover,
                        &:focus {
                                color: $c_theme;
                                text-decoration: none;
                        }
                        
                        &:before {
                                content: '>';
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 15;
                                
                        }
                }
        }
}

.btn {
        &--next,
        &--prev {
                display: block;
                width: 25px;
                height: 19px;
                background-position: center;
                background-repeat: no-repeat; 
                cursor: pointer;
        }

        &--prev {
                background-image: url('../assets/arrow-back.png');
        }

        &--next {
                background-image: url('../assets/arrow.png');
        }
        
        .btn--next{
                position: relative;
                top: 3px;
                display: inline-block;
                margin-left: 5px;
        }
}
    
.ico {
        &--twitter {
                width: 100px;
                height: 112px;
                display: block;
                background-position: center;
                background-repeat: no-repeat;        
                background-image: url('../assets/twitter.png');
        }
}

.page-content {
        .btn--prev {
                margin: 25px 0 0;
        }
        
        h2{
                font-size: 14px;	
                line-height: 22px;
        }
}

.page-title {
        margin: 18px 0;
        font-size: 27px;
        font-weight: 400;
        text-transform: uppercase;
}

.page-sub-title {
        margin: 15px 0;
        font-size: 19px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: -0.08em;
}

.slider-home {
        &--wrapper{
                margin: 0 20px 15px 20px;
                
                @include breakpoint($xl) {
                        margin: 0 -15px 0 0;
                }
        }
        
        &--body{                
                position: relative;
                background-repeat: no-repeat;
                background-size: 0;
                background-position: left -25px bottom;
                
                @include breakpoint($xl) {                        
                        background-size: 75%;
                }
                
                @include breakpoint($xl) {
                        height: 650px;   
                        background-size: initial;
                        background-position: top;
                }
        }
    
        &__quote,
        &__name,
        &__body{                                
                @include breakpoint($xl) {
                        float: right;
                        width: 50%;
                        clear: both;                        
                }
        }
        
        &__quote {            
                margin: 40px 0 20px;
                color: $c_blueText;
                font-size: 22px;
                line-height: 24px;                
                
                @include breakpoint($xl) {
                        margin: 76px 0 20px;
                        width: 300px;
                        font-size: 28px;
                        line-height: 30px;
                        text-align: center;
                }
        }

        &__name { 
                display: none;
                margin: 0 0 15px;
                font-size: 18px;                
                text-align: right;
                
                @include breakpoint($xl) {
                        display: block;
                        margin: 0;
                        width: 300px;
                }
        }

        &__body {               
                //text-align: right;
                //padding-left: 120px;
                @include breakpoint($xl) {
                        position: absolute;
                        top: 375px;
                        right: 0;
                        width: 250px;
                        padding-left: 0;
                        text-align: right;
                }
        }

        &__person {
                float: right;
                width: 125px;
                height: 100px;
                margin-bottom: 35px;
                padding-top: 105px;
                background-size: 140px;
                background-position: center;
                background-repeat: no-repeat;
                font-size: 16px;
                text-align: center;

                @include breakpoint(550px) {
                        width: 200px;
                        height: 170px;
                        padding-top: 170px;
                        background-size: 220px;
                        font-size: 18px;
                }

                @include breakpoint($xl) {
                        display: none;
                }
        }
}

.bx-wrapper {
        position: relative;
        margin: 0;
        padding: 0;
        box-shadow: none;
        border: none;   
        background-color: transparent;
        
        .bx-pager {
                top: 2px;
                right: -5px;
                bottom: initial;
                display: inline-block;
                width: auto;
                padding: 0;
                text-align: right;

                &-item {
                        font-size: 0;
                        line-height: 0;
                }
                
                &.bx-default-pager {
                        a {
                                display: block;
                                width: 14px;
                                height: 14px;
                                margin: 0 5px;
                                background-color: $c_gray;
                                border: 2px solid $c_gray;
                                border-radius: 50%;
                                
                                &:hover,
                                &:focus {
                                        background-color: $c_gray;
                                        border: 2px solid $c_gray;
                                }
                                    
                                
                                &.active {
                                    background-color: $c_theme;
                                    border-color: $c_theme;
                                }
                        }                    
                }
        }
}